
import { Component, Vue } from "vue-property-decorator";
import { readAdminUsers, readFeedbacks } from "@/store/admin/getters";
import { dispatchGetFeedbacks, dispatchSendFeedbackResponse } from "@/store/admin/actions";

@Component
export default class AdminUsers extends Vue {
  public pages = [5, 10, 20, 25, 50]
  public pagination = {
    rowsPerPage: 25,
    page: 1,
    totalItems: 0,
  };
  public dialogOpenedForId = -1;
  public feedbackText = '';

  isLoading = false;

  public headers = [
    {
      sortable: false,
      text: "Description",
      value: "description",
      align: "left",
    },
    {
      text: "Submitted on",
      sortable: false,
      value: "created_on",
      align: "left",
    },
    {
      text: "Submited by",
      sortable: false,
      value: "owner_email",
      align: "left",
    },
    {
      text: 'Actions',
      value: 'id',
      align: 'center',
      sortable: false
    },
  ];

  async paginate(newData) {
    this.isLoading = true;
    await dispatchGetFeedbacks(this.$store, { pageNumber: newData.page - 1, pageSize: newData.rowsPerPage });
    this.isLoading = false;
  }

  get users() {
    return readAdminUsers(this.$store);
  }

  get feedbacks() {
    return readFeedbacks(this.$store).feedbacks.map(f => ({ ...f, created_on: new Date(f.created_on).toLocaleDateString() }));
  }

  get totalNumber() {
    return this.feedbacksInfo.total;
  }

  get feedbacksInfo() {
    return readFeedbacks(this.$store);
  }


  public async mounted() {
    await dispatchGetFeedbacks(this.$store, { pageNumber: this.pagination.page - 1, pageSize: this.pagination.rowsPerPage });
  }

  public openModal(id: number) {
    this.feedbackText = '';
    this.dialogOpenedForId = id;
  }

  public closeModal() {
    this.dialogOpenedForId = -1;
  }

  public async sendFeedbackResponse(feedbackId: number) {
    const shouldCloseModal = await dispatchSendFeedbackResponse(this.$store, {
      response: this.feedbackText,
      feedbackId,
    });
    if (shouldCloseModal) {
      this.closeModal();
      this.feedbackText = '';
    }
  }
}
